import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
import API from '../../../lib/api'
import Dialog from '../../../components/Popup/Dialog'
import './style.css'

export default class ProductCacheClear extends Component {
  constructor(props) {
    super(props)
    this.clearCache = this.clearCache.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.state = {
      textAreaValue: '',
      selectType: 'Product',
      showDialog: false,
      dialogMessage: '',
      status: 'success',
    }
  }

  clearCache() {
    const { selectType, textAreaValue } = this.state
    const result = textAreaValue.split('\n').map(Number)
    const uniqueArray = [...new Set(result)]
    if (uniqueArray.length > 100) {
      alert(
        `More than 100 id shared, ids starting from ${uniqueArray[100]} will be ignored`
      )
    }
    const api = new API({ url: '/catalogue-service/product-cache/remove' })
    const data =
      selectType === 'Product'
        ? { product_ids: result }
        : { category_ids: result }
    api.delete(data).then(
      (response) => {
        this.setState({
          showDialog: true,
          status: 'success',
          dialogMessage: response.data,
        })
      }
    )
  }

  handleValueChange(e) {
    this.setState({
      textAreaValue: e.target.value,
    })
  }

  toggleDialog() {
    this.setState({
      showDialog: false,
      dialogMessage: '',
    })
  }
  handleTypeChange(e) {
    this.setState({
      selectType: e.target.value,
    })
  }
  render() {
    const { status, showDialog, dialogMessage, textAreaValue, selectType } =
      this.state
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1>Product or Category Cache Clear</h1>
        <p>Please select type of cache to clear</p>
        <select
          id="selectType"
          value={selectType}
          onChange={this.handleTypeChange}
        >
          <option value="Product">Product</option>
          <option value="Category">Category</option>
        </select>
        <p>Maximum 100 {selectType} ids, rest will be ignored</p>
        <textarea
          className="productIds"
          placeholder={`Please enter ${selectType} ids`}
          rows={8}
          cols={40}
          value={textAreaValue}
          onChange={this.handleValueChange}
        />
        <button
          disabled={!this.state.textAreaValue}
          type="click"
          className="primary button"
          onClick={this.clearCache}
        >
          Apply
        </button>
        <Dialog
          className={status}
          show={showDialog}
          close={this.toggleDialog}
          message={dialogMessage}
          closeText="OK"
        />
      </AuthenticatedPage>
    )
  }
}
