const data = {
  'menu.selectLanguage.heading': 'Select language',
  'menu.changePassword.heading': 'Change Password',
  'menu.item.operations': 'Operations',
  'menu.item.dashboard': 'Dashboard',
  'menu.item.stores': 'Stores',
  'menu.item.customers': 'Customers',
  'menu.item.orders': 'Orders',
  'menu.item.delivery-orders': 'Delivery Orders',
  'menu.item.sale-orders': 'Sale Orders',
  'menu.item.customer-support': 'Customer Support',
  'menu.item.trip-planner': 'Trip Planner',
  'menu.item.logistics': 'Logistics',
  'menu.item.catalogue': 'Catalogue',
  'menu.item.marketplace': 'Marketplace',
  'menu.item.categories': 'Categories',
  'menu.item.brands': 'Brands',
  'menu.item.products': 'Products',
  'menu.item.batch-upload-jobs': 'Batch Upload Jobs',
  'menu.item.scheduled-batch-uploads': 'Scheduled Batch Uploads',
  'menu.item.batch-uploads-status-tracker': 'Batch Uploads Status Tracker',
  'menu.item.product-cache-clear': 'Product Cache Clear',
  'menu.item.tags': 'Tags',
  'menu.item.substitution-groups': 'Substitution Groups',
  'menu.item.stock-override': 'Stock Overrides',
  'menu.item.sellers': 'Sellers',
  'menu.item.marketing': 'Marketing',
  'menu.item.blog': 'Blog',
  'menu.item.pages': 'Pages',
  'menu.item.challenges': 'Challenges',
  'menu.item.settings': 'Settings',
  'menu.item.basic-information': 'Basic information',
  'menu.item.contact-details': 'Contact Details',
  'menu.item.themes': 'Website',
  'menu.item.extensions': 'Extensions',
  'menu.item.seo': 'SEO',
  'menu.item.users': 'Multi User Support',
  'menu.item.mobile-apps': 'Mobile Apps',
  'menu.item.payment-configuration': 'Online Payment',
  'menu.item.my-account': 'My account',
  'menu.item.account-balance': 'Account Balance',
  'menu.item.change-password': 'Change Password',
  'menu.item.logout': 'Logout',
  'menu.item.delivery-area': 'Delivery Area',
  'menu.item.notifications': 'Notifications',
  'menu.item.website-link': 'Your online store',
  'menu.item.zone-picking-queue': 'Zone Picking Queue',
  'menu.item.vehicles': 'Vehicles',
  'menu.item.vehicle-planning': 'Vehicle Planning',
  'menu.item.trips': 'Trips',
  'menu.item.manage-runners': 'Manage Runner Executives',
  'menu.item.designations': 'Designations',
  'menu.item.leave-summary': 'Leave Summary',
  'menu.item.attendance-summary': 'Attendance Summary',
  'menu.item.hr': 'HR',
  'menu.item.leave-management': 'Leave Management',
  'menu.item.employees': 'Employees',
  'menu.item.my-leaves': 'My Leaves',
  'menu.item.rack-management': 'Rack Mangement',
  'menu.item.disputes': 'Disputes',
  'menu.item.returns': 'Pending Returns',
  'menu.notificationToday.empty': 'There are no notification shown here',
  'menu.item.slots': 'Slots',
  'menu.item.roles': 'Roles',
  'menu.item.tawk': 'Tawk.to Live Chat',
  'menu.item.Seo': 'SEO',
  'menu.item.metadata': 'Custom Fields',
  'menu.item.analytics': 'Analytics',
  'menu.item.instore': 'Instore Processing',
  'menu.item.pickers': 'Manage Pickers',
  'menu.item.logistics-configuration': 'Logistics',
  'menu.item.user-hover': 'user hover',
  'menu.item.offers': 'Offers',
  'menu.item.calls': 'Calls',
  'menu.item.web-banners': 'Banners',
  'menu.item.evouchers': 'E-Vouchers',
  'menu.item.lucky-draws': 'Lucky Draw',
  'menu.item.omni-challenges': 'Omni Challenges',
  'menu.item.carousels': 'Carousels',
  'menu.item.maintenance': 'Maintenance',
  'menu.item.merchants': 'E&B Merchants',
  'menu.item.devices': 'E&B Terminal Devices',
  'menu.item.partners': 'Partners',
  'menu.item.promotions': 'Promotions',
  'menu.item.recommender': 'Recommender',
  'menu.item.search-campaigns': 'Search Campaigns',
  'menu.item.campaign-labels': 'Campaign Labels',
  'menu.item.search-configuration': 'Search Overrides',
  'menu.item.communication-configuration': 'Communication',
  'menu.item.customer-tags': 'Customer Tags',
  'menu.item.capacity-planning': 'Capacity Planning',
  'menu.item.slot-charges': 'Slot Charges',
  'menu.item.order-config': 'Order Config',
  'menu.item.cleanup': 'Order Cleanup',
  'menu.item.payments': 'Pay via FairPrice app',
  'menu.item.egifting-orders': 'E-Gifting Orders',
  'menu.item.egifting-orders-pending-bank-approval':
    'E-Gifting Orders - Pending Bank Approval',
  'menu.item.sku-meta-upload': 'SKU Meta Bulk Update',
  'menu.item.qc': 'QC',
  'menu.item.recipe': 'Recipe',
  'menu.item.batch-upload': 'Batch Upload',
  'menu.item.featured-recipes': 'Featured Recipes',
  'menu.item.order-management': 'Order Management',
  'menu.item.media-monetization': 'Retail Media',
  'menu.item.media-storelist': 'Inventory Discovery',
  'menu.item.media-allcampaigns': 'Campaign Reservation',
  'menu.item.media-packagemanagement': 'Package Management',
  'menu.item.media-assetmanagement': 'Asset Management',
  'menu.item.media-creativemanagement': 'Creative Management',
  'menu.item.rewards': 'Rewards',
  'menu.item.admin': 'Admin',
  'menu.item.action-trail': 'Action Trail',
  'menu.item.preorder': 'Offline Preorder',
  'menu.item.sng-actions': 'S&G Actions',
  'menu.item.preorders': 'Pre Order',
  'menu.item.preorder-campaigns': 'Pre-Order Campaign',
  'menu.item.define-festive-campaigns': 'Define Festive Campaign',
  'menu.item.app-home': 'App Home',
  'menu.item.banner-upload': 'Banner Upload',
  'menu.item.pickers-today': 'Pickers Today',
  'menu.item.timeslot-blocking': 'Block Time Slots',
  'menu.item.bulk-operations': 'Bulk Operations',
  'menu.item.tiles-dressup': 'Tiles Dressup',
  'menu.item.splash-dressup': 'Splash Dressup',
  'menu.item.dynamic-layout': 'Dynamic Layout',
  'menu.item.card-content': 'Card Content',
  'menu.item.popup': 'Popup',
  'menu.item.omni-journey': 'Omni Journey',
  'menu.item.vouchers': 'Vouchers',
  'menu.item.gifting-entities': 'Gifting Entities',
  'menu.item.in-store-preorder': 'In-Store Preorder',
  'menu.item.offline': 'Offline Pre-order',
  'menu.item.link': 'Link',
  'menu.item.voucher-center-campaigns': 'Voucher Center Campaigns',
}
export default data
