import React, { useContext } from 'react'
import Table, { Header, Row, Cell } from '../../../../../components/Table'
import { getMessage } from '../../../../../lib/translator'
import { Dialog } from '../../../../../components/Popup'
import {
  BaseForm,
  MultiTextInput,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import _ from 'lodash'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { tags } from '../PacklistView'

const validLabelWithoutZonePrefix = new RegExp(/^([A|B|C|F|W]-\d+|DB-\d+)$/)

const packlistOwnerType = {
  vendor: 'PACKLIST_OWNER_TYPE_VENDOR',
  store: 'PACKLIST_OWNER_TYPE_STORE',
}

class PacklistPackingDetails extends BaseForm {
  constructor(props) {
    super(props)
    this.getPackageName = this.getPackageName.bind(this)
    this.getPackageArray = this.getPackageArray.bind(this)
    this.getPacklistId = this.getPacklistId.bind(this)
  }

  componentDidMount() {
    if (this.props.itemEditMode) {
      const values = {}
      const packlistPackages = {}

      this.props.packlistDetails.map((packlist) => {
        packlistPackages[this.getPacklistId(packlist)] = this.getPackageArray(
          packlist.packages
        )
      })

      values['packlistPackages'] = packlistPackages
      values['original'] = JSON.parse(JSON.stringify(packlistPackages))

      this.setState({
        values: values,
        invalidLabel: false,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.itemEditMode) {
      if (
        !this.state.invalidLabel &&
        !_.isEqual(
          this.getState(['packlistPackages']),
          this.getState(['original'])
        )
      ) {
        this.props.updateCrates(
          this.getState(['packlistPackages']),
          this.getState(['original'])
        )
      }
    }
  }

  formatLabelChanges = (event, packlist) => {
    const prevEvent = this.getState([
      'packlistPackages',
      this.getPacklistId(packlist),
    ])
    if (prevEvent.length > event.length) {
      return event
    }

    if (event.length > 0) {
      if (
        this.props.zonePrefix[packlist.tag] &&
        packlist.ownerType === packlistOwnerType.store
      ) {
        if (!validLabelWithoutZonePrefix.test(event[event.length - 1])) {
          this.setState({
            invalidLabel: true,
          })
          event.pop()
          return event
        } else {
          event[event.length - 1] =
            `${this.props.zonePrefix[packlist.tag]}-${event[event.length - 1]}`
        }
      }
    }

    return event
  }

  resetWrongLabel = () => {
    this.setState({
      invalidLabel: false,
    })
  }

  getPacklistTitle = (packlist) => {
    if (packlist.tag === 'all' && packlist.ownerType && packlist.ownerName) {
      return packlist.ownerType === packlistOwnerType.vendor
        ? packlist.ownerName
        : tags[packlist.tag]
    }
    return tags[packlist.tag]
  }

  getPackageName = (packages) => {
    return packages.map((pack) => pack.packageCode).join(', ')
  }

  getPackageArray = (packages) => {
    const ret = []
    packages.map((pack) => {
      ret.push(pack.packageCode)
    })
    return ret
  }

  getPacklistId = (packlist) => {
    return packlist != null ? packlist.id : null
  }

  render() {
    const { itemEditMode, packlistDetails } = this.props
    return (
      <div className="packing-details">
        <h3>{getMessage('order.details.packing.heading')}</h3>
        <Table>
          <Header>
            <Cell>{getMessage('order.packing.type')}</Cell>
            <Cell></Cell>
            <Cell>{getMessage('order.packing.labels')}</Cell>
          </Header>
          {packlistDetails.map((packlist, packlistindex) => {
            return (
              <React.Fragment key={`packing-item-${packlistindex}`}>
                {itemEditMode ? (
                  <>
                    <Row>
                      <Cell>
                        {' '}
                        <b>{this.getPacklistTitle(packlist)}</b> (Packlist id:{' '}
                        {packlist.id})
                      </Cell>
                      <Cell></Cell>
                      <Cell></Cell>
                    </Row>
                    <Row key={`packing-item-${packlistindex}2`}></Row>
                    <Cell> Crates </Cell>
                    <Cell className="zone-prefix" align="right">
                      {this.props.zonePrefix[packlist.tag] &&
                      packlist.ownerType === packlistOwnerType.store
                        ? this.props.zonePrefix[packlist.tag] + ' - '
                        : ''}
                    </Cell>
                    <Cell>
                      <MultiTextInput
                        placeholder={getMessage(
                          'order.details.packages.multi-text.placeholder'
                        )}
                        name={`packages-crates`}
                        classname="packages"
                        value={
                          this.state.values['packlistPackages']
                            ? [this.getPacklistId(packlist)]
                            : []
                        }
                        {...this.generateStateMappers({
                          stateKeys: [
                            'packlistPackages',
                            this.getPacklistId(packlist),
                          ],
                          validationType: VALIDATION_TYPES.ONS,
                          formatChange: (event) =>
                            this.formatLabelChanges(event, packlist),
                        })}
                      />
                    </Cell>
                    <Dialog
                      title={getMessage('order.details.packlist.invalidLabel')}
                      show={this.state.invalidLabel}
                      close={this.resetWrongLabel}
                      closeText={getMessage(
                        'order.details.edit-packed.error.closeDialog'
                      )}
                    />
                  </>
                ) : (
                  <>
                    {packlist.packages.length === 0 ? null : (
                      <>
                        <Row>
                          <Cell>
                            <b>{this.getPacklistTitle(packlist)}</b>
                            &nbsp;
                            <span style={{ color: '#80959d' }}>
                              (Packlist id: {packlist.id})
                            </span>
                          </Cell>
                          <Cell></Cell>
                          <Cell></Cell>
                        </Row>
                        <Row key={`packing-item-${packlistindex}2`}></Row>
                        <Cell> Crates </Cell>
                        <Cell></Cell>
                        <Cell>{this.getPackageName(packlist.packages)}</Cell>
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            )
          })}
          <footer></footer>
        </Table>
      </div>
    )
  }
}

const PacklistPackingDetailsWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  const zonePrefix = splits?.[SPLIT_FEATURES.ZONE_PREFIX_LABEL]
    ? JSON.parse(splits[SPLIT_FEATURES.ZONE_PREFIX_LABEL].config)
    : {}

  return <PacklistPackingDetails zonePrefix={zonePrefix} {...props} />
}

export default PacklistPackingDetailsWrapper
